/* eslint-disable import/no-named-as-default-member */
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "@/components/elements/Button"
import DatePicker from "@/components/forms/DatePicker"
import SelectGeneric from "@/components/forms/SelectGeneric"
import TextInput from "@/components/forms/TextInput"
import WithError from "@/components/forms/WithError"
import {
  Dialog,
  DialogContent,
} from "@/components/ui/dialog"
import LogoStackedVerticallyWithText from "assets/logos/StackedVerticallyWithText"
import {
  useCertificateTypes,
  useRequestCertificate,
  useSystemInfo,
} from "hooks/data"
import { ELanguages } from "src/constants"
import { TLanguageCodes } from "types/helpers"
import { cn } from "utils"

export type TRequestCertificateFormValues = {
  employeeNumber?: string
  fullName: string
  dateOfBirth: string
  lineManager?: string
  certificateTypeID: number
  languageID: string
  comment?: string
}

type RequestCertificateFormProps = {
  predefinedLanguage: TLanguageCodes
  setOpen?: (open: boolean) => void
}

const RequestCertificateFormContent: React.FC<RequestCertificateFormProps> = ({ setOpen, predefinedLanguage }) => {
  const systemInfo = useSystemInfo()
  const router = useRouter()

  const { t, i18n } = useTranslation("login", {
    keyPrefix: "requestCertificateModal",
  })

  const specialInstructionsText: string | null = systemInfo?.data?.EnhancedCertificateRequestInstructionML?.[predefinedLanguage] || ""

  const {
    control,
    register,
    trigger,
    getValues,
    formState,
    setError,
    setValue
  } = useForm<TRequestCertificateFormValues>({
    mode: "onBlur",
    defaultValues: {
      certificateTypeID: 2,
      languageID: predefinedLanguage,
    },
  })

  React.useEffect(() => {
    void i18n.changeLanguage(predefinedLanguage)
    setValue("languageID", predefinedLanguage)
  }, [i18n, predefinedLanguage, setValue])

  const { errors } = formState

  const requestCertificate = useRequestCertificate({
    onSuccess() {
      if (setOpen) {
        setOpen(false)
      } else {
        setTimeout(() => {
          void router.push("/public/login")
        }, 1000 * 2)
      }
    },
  })

  const languagesOptions: { value: ELanguages; primary: string }[] = [
    ...(systemInfo?.data?.EnableLanguageGe
      ? [{ value: ELanguages.DE, primary: "Deutsch" }]
      : []),
    ...(systemInfo?.data?.EnableLanguageEn
      ? [{ value: ELanguages.EN, primary: "English" }]
      : []),
    ...(systemInfo?.data?.EnableLanguageFr
      ? [{ value: ELanguages.FR, primary: "Francais" }]
      : []),
    ...(systemInfo?.data?.EnableLanguageIt
      ? [{ value: ELanguages.IT, primary: "Italiano" }]
      : []),
  ]

  const certificateTypesQuery = useCertificateTypes()
  const certificateTypesSelectOptions =
    certificateTypesQuery.data?.map((type) => ({
      value: type.CatZeugnisTypID,
      primary: type.BezeichnungML[predefinedLanguage] ?? type.Bezeichnung,
    })) ?? []

  async function onSubmit() {

    await trigger()

    const { employeeNumber, fullName, dateOfBirth, lineManager, comment } = getValues()

    if (!fullName) {
      setError("fullName", { type: "required" })
      return
    }

    if (!dateOfBirth) {
      setError("dateOfBirth", { type: "required" })
      return
    }

    if (Object.keys(errors).length > 0) return

    setValue("fullName", fullName.trim())
    employeeNumber && setValue("employeeNumber", employeeNumber.trim())
    lineManager && setValue("lineManager", lineManager.trim())
    comment && setValue("comment", comment.trim())

    requestCertificate.mutate(getValues())
  }

  return (
    <>
      <div>
        <h3 className="text-md font-semibold leading-6 text-gray-900">
          {t("title")}
        </h3>
        <p className="mt-2 text-sm text-gray-500">{t("subtitle")}</p>
      </div>
      {specialInstructionsText && (
        <div className="bg-paleYellow rounded-[8px] py-4 my-4 px-2">
          <h4 className="font-bold text-sm">{t("instructions")}</h4>
          <p className="text-sm text-gray-800">
            {specialInstructionsText}
          </p>
        </div>
      )}
      <div className="flex flex-col sm:grid sm:grid-cols-2 sm:items-baseline gap-4">
        <div className="sm:col-span-2 sm:w-[calc(50%-0.5rem)]">
          <TextInput
            id="employeeNumber"
            label={t("employeeNumber")}
            {...{ register }}
          />
        </div>

        <WithError error={errors.fullName}>
          <TextInput
            id="fullName"
            label={t("fullName")}
            {...{ register }}
            registerOptions={{
              required: true,
            }}
          />
        </WithError>

        <WithError error={errors.dateOfBirth}>
          <DatePicker
            id="dateOfBirth"
            label={t("dateOfBirth")}
            allowDatesBeforeToday
            controllerProps={{
              name: "dateOfBirth",
              // @ts-ignore
              control,
              rules: {
                required: true,
              },
            }}
          />
        </WithError>

        <TextInput
          id="lineManager"
          label={t("lineManager")}
          {...{ register }}
        />

        <WithError error={errors.certificateTypeID}>
          <Controller
            control={control}
            name="certificateTypeID"
            render={({ field }) => (
              <SelectGeneric
                label={t("certificateTypeID")}
                options={certificateTypesSelectOptions}
                value={certificateTypesSelectOptions.find(
                  (item) => item.value === field.value
                )}
                onChange={({ value }) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </WithError>

        <Controller
          control={control}
          name="languageID"
          render={({ field }) => (
            <SelectGeneric
              label={t("languageID")}
              options={languagesOptions}
              value={languagesOptions.find((item) => item.value === field.value)}
              onChange={({ value }) => {
                field.onChange(value);
              }}
            />
          )}
        />

        <TextInput
          id="comment"
          label={t("comment")}
          multiline
          defaultValue={getValues("comment")}
          {...{ register }}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button secondary onClick={() => setOpen ? setOpen(false) : router.push("/public/login")}>
          {t("cancel")}
        </Button>
        <Button onClick={onSubmit}>{t("submit")}</Button>
      </div>
    </>
  )
}

const RequestCertificateForm: React.FC<RequestCertificateFormProps> = ({
  predefinedLanguage,
}) => {
  const { t } = useTranslation("login", {
    keyPrefix: "requestCertificateModal",
  })

  return (
    <>
      <Head>
        <title>{`Skriba - ${t("title")}`}</title>
      </Head>
      <div
        className={cn([
          "grid h-screen grid-rows-login",
          "place-items-center",
          "py-12 sm:px-6 lg:px-8",
        ])}
      >
        <div className="px-3 pb-3 sm:w-full sm:max-w-[764px]">
          <div className="flex justify-center">
            <LogoStackedVerticallyWithText className="h-36 w-36" />
          </div>
          <div className="space-y-8 mt-16 px-4 py-8 border border-black rounded-2xl">
            <RequestCertificateFormContent predefinedLanguage={predefinedLanguage} />
          </div>
        </div>
      </div>
    </>
  )
}

export const RequestCertificateModal: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  predefinedLanguage: TLanguageCodes;
}> = ({ open, setOpen, predefinedLanguage }) =>
  (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="rounded-lg mx-auto w-full max-w-[calc(100%-8rem)] sm:max-w-[764px]">
        <RequestCertificateFormContent setOpen={setOpen} predefinedLanguage={predefinedLanguage} />
      </DialogContent>
    </Dialog>
  )

export default RequestCertificateForm
